<template>
  <div>
    <role-list />
  </div>
</template>

<script>
import RoleList from './components/RoleList.vue'
export default {
  components: { RoleList },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">

</style>
