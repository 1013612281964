<template>
  <div>
      <el-dialog
        :title="title"
        :visible.sync="popShow"
        width="800px"
        :before-close="handleClose">
          <div
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="#fff"
          >
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  label-width="80px"

              >
                <el-form-item label="角色名" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="角色标识" prop="identification">
                  <el-input :disabled="title === '编辑角色'" v-model="ruleForm.identification"></el-input>
                </el-form-item>
              </el-form>
              <div class="accredit">
                <div class="text">角色授权</div>

                <el-tree
                    :data="menuList"
                    show-checkbox
                    node-key="name"
                    ref="tree"
                    default-expand-all
                    :props="defaultProps"
                    @check="check"
                    :default-checked-keys="defaultCheckedKeys"

                >
              <span class="custom-tree-node" slot-scope="{ data }">
                <span>{{ data.title }}</span>
              </span>
                </el-tree>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="sure">确 定</el-button>
              </div>
          </div>
      </el-dialog>

  </div>
</template>

<script>

export default {
  props:{
      popShow:{
          type:Boolean
      },
      menuList:{
        type:Array
      },

    title:{
        type:String
    },
    editData:{
        type:Object
    }
  },
  data () {
    return {
        ruleForm:{
            name:'',
          identification:''
        },
        rules:{
            name:[
              { required: true, message: '请输入角色名称', trigger: 'blur' },
            ],
          identification:[
            { required: true, message: '请输入角色标识', trigger: 'blur' },
            { min: 2, message: '角色标识最少两位英文', trigger: 'blur' },
            {message:'标识只能为英文', trigger: 'blur', pattern:/^[a-z]+$/i,},
          ]
        },
         defaultProps: {
          children: 'all_children',
          label: 'title'
        },
        arr:[],
      defaultCheckedKeys:[],
      loading:true
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

    check(checkedNodes,checkedKeys){
      //父子节点
      this.arr = [...checkedKeys.checkedKeys,...checkedKeys.halfCheckedKeys]
      // this.arr = [...checkedKeys.checkedKeys]
    },

      handleClose(){
      this.ruleForm ={
          name:'',
          identification:''
        }
          this.$emit('pop',false)
          this.$refs.ruleForm.resetFields()
          this.defaultCheckedKeys = []
        this.loading = true
      },

        // 确认
        sure(){
          this.$refs.ruleForm.validate((val)=>{
            if(val) {
              if(JSON.stringify(this.editData) !=='{}'  && this.ruleForm.name === this.editData.title) {
                this.ruleForm.name = ''
              }
              this.$emit('name',{title:this.ruleForm.name || undefined,name:this.ruleForm.identification,permissions:this.arr})
              this.handleClose()
              this.ruleForm.name = ''
            } else {
              this.$message.error('请填写完毕再保存')
            }

          })
        }
  },


  watch:{
    editData:{
      handler(){
       this.$nextTick(()=>{
         this.ruleForm.name = this.editData?.title
         this.ruleForm.identification =  this.editData?.name
         let parentId = null
         let list = []
         this.editData.permissions.forEach(val=>{
           list.push(val.name)
           if(val.parent === 0) {
             //获取一级id
             parentId = val.id
           } else {
             if(val.parent  !== parentId) {
               this.defaultCheckedKeys.push(val.name)
             }
           }

         })
         console.log(list)
         this.arr =  list
         this.$refs.tree.setCheckedKeys(this.defaultCheckedKeys)
       })
      }
    },
    menuList:{
      handler(){
        setTimeout(()=>{
          this.loading = false
        },500)
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog{
    // height: 571px;
    background: #FFFFFF;
    box-shadow: 0px 6px 16px 4px rgba(51, 51, 51, 0.3);
    border-radius: 10px;
    padding: 0 20px;
    .el-dialog__header{
        // margin: 0 20px;
        padding-left:0;
        padding-right: 0;
        border-bottom: 1px solid #d8d8d8;
    }
    .el-dialog__body{
        padding: 20px 40px;
        border-bottom: 1px solid #d8d8d8;
    }
}
.accredit{
    display: flex;
    height:500px ;
    overflow: auto;
  margin-bottom: 20px;
    .el-tree{
        margin-left: 15px;
        width: 500px;
    }
    /deep/.el-tree-node__content{
        height: 40px;
    }
}
.text{
  padding-top: 15px;
}
/deep/.el-dialog {
  height: 800px;
  margin-top: 8vh !important;
}

/* 滚动槽（轨道）宽高 */
::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
  border-radius: 5px;
}
::-webkit-scrollbar-thumb{
  width: 10px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
  border-radius: 5px;
  background: #eee;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
