<template>
  <div>
    <!-- 头部 -->
    <div class="user-top">
      <el-button class="new_btn" size="mini" @click="rolepop(0)">新增角色</el-button>
    </div>
    <!-- 搜索栏 -->
    <div class="operation">
      <div class="operation_left">
        <el-button @click="batchDeletion" :disabled="!checkedCities.length"
                   :class="{'deleteColor':!!checkedCities.length}">批量删除
        </el-button>
      </div>
    </div>
    <!-- 主体list -->
    <div class="list">
      <el-row class="list_top">
        <el-col :span="1" class="check">
          <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">
          </el-checkbox>
        </el-col>
        <el-col :span="20">角色名称</el-col>
        <el-col :span="3">操作</el-col>
      </el-row>
      <div class="list_body">
        <el-row class="list_item" v-for="(item, index) in rolelist" :key="index">
          <el-col :span="1" class="check">
            <el-checkbox-group :disabled="!item.team_id" v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox :label="item.id"></el-checkbox>
            </el-checkbox-group>
          </el-col>
          <el-col :span="20">{{ item.title }}</el-col>
          <!--          v-if="!!item.team_id"-->
          <el-col :span="3" class="color" :class="{'grayDisabled':!item.team_id}">
            <span @click="modify(item.id,item.team_id)" style="margin-right: 10px;">修改</span>
            <span @click="deleteRole(item.id,item.team_id)">删除</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    //新增编辑角色弹窗-->

    <role-pop
        :popShow="popShow"
        @pop="pop"
        :menuList="menuList"
        @name="rolrName"
        :title="title"
        :editData="editData"
    />

  </div>
</template>

<script>
import {getRoleList, permissionList, editRole, addRole, DeletRole, getDetails} from '@/api/rbac'
import RolePop from './RolePop.vue'

export default {
  components: {RolePop},
  data() {
    return {
      options: [
        {
          value: 1,
          label: '删除'
        }
      ],
      // 显示选项
      options_num: [
        {
          value: 20,
          label: '20'
        },
        {
          value: 30,
          label: '30'
        },
        {
          value: 40,
          label: '40'
        },
        {
          value: 50,
          label: '50'
        },
      ],
      value: '',
      // 显示值
      num_value: 20,
      // 搜索值
      search_inp: '',
      // 弹窗显示
      popShow: false,
      // 菜单列表
      menuList: [],
      title: '新增角色',
      // 角色列表
      rolelist: [],
      roleId: null,
      checkAll: false, //头部复选框
      checkedCities: [], //选择的商品
      isIndeterminate: false, //全选控制
      dialogVisible: false,   //删除弹窗
      sure: false,   //确认
      editData: {},//编辑数据
    }
  },
  created() {
    this.roleList()
  },
  mounted() {

  },
  methods: {
    //   获取角色列表
    async roleList() {
      try {
        const {data} = await getRoleList()
        this.rolelist = data
      } catch (e) {
        this.$message.error(e)
      }
    },


    // 确定/编辑 新增角色
    async rolrName(val) {
      const {title, name, permissions} = val
      if (!this.roleId) {
        //新增
        try {
          const {message} = await addRole({title, name, permissions})
          this.$message({
            message: message,
            type: 'success'
          });
          await this.roleList()
        } catch (e) {
          this.$message.error(e);
        }
      } else {
        //编辑
        try {
          const {message} = await editRole({roleId: this.roleId, permissions, title})
          this.$message({
            message,
            type: 'success'
          });
          await this.roleList()
        } catch (e) {
          this.$message.error(e);
        }
      }
    },


    //获取角色授权列表
    async getMenuList() {
      try {
        const {data, msg} = await permissionList()
        this.menuList = data
      } catch (e) {
        this.$message.error(e)
      }
    },


    //新增
    rolepop() {

      this.popShow = true
      this.title = '新增角色'
      this.getMenuList()

    },


    //获取详情
    async modify(id, val) {
      if (!!val) {
        this.roleId = id
        this.popShow = true
        await this.getMenuList()
        this.title = '编辑角色'
        try {
          const {data} = await getDetails({roleId: id})

          this.editData = data
        } catch (e) {
          this.$message.error(e?.msg)
        }
      }

    },


    // 删除单个角色
    deleteRole(id, val) {
      if (!!val) {
        this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {

          const {message} = await DeletRole({roleId: [id]})
          await this.roleList()
          this.$message.success(message)
        }).catch(e => {
          if (e !== 'cancel') {
            this.$message.error(e)
          }
        })
      }

    },

    //批量删除
    batchDeletion() {
      // let ids = this.checkedCities.join(',')

      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {

        await DeletRole({roleId: this.checkedCities})

        await this.roleList()
        this.$message.success(message)
      }).catch(e => {
        if (e !== 'cancel') {
          this.$message.error(e)
        }
      })
    },

    pop(val) {
      this.popShow = val
      this.roleId = null
    },
    // 全选框
    handleCheckAllChange(val) {

      let arr = []
      this.rolelist.forEach(e => {
        if (!!e.team_id) {
          arr.push(e.id)
        }
      });

      this.checkedCities = val ? arr : []
      this.isIndeterminate = false

    },
    // 单选
    handleCheckedCitiesChange(val) {
      let arr = []

      this.rolelist.forEach(e => {
        arr.push(e.id)
      });
      this.checkAll = val.length === this.rolelist.length
      this.isIndeterminate = val.length > 0 && val.length < this.rolelist.length


    },

  }
}
</script>

<style scoped lang="less">
.deleteColor {
  background: #FA4A14;
  color: #fff;
}

.user-top {
  .new_btn {
    width: 130px;
    height: 40px;
    background: #FA4A14;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
  }

  padding: 0 0 20px;
  border-bottom: 1px solid #d8d8d8;
}

.operation {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  .operation_left {
    .batch {
      margin-left: 10px;
    }

    .s_text {
      margin-left: 20px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }

    .num {
      width: 60px;
    }
  }

  .operation_rigth {
    width: 412px;
    height: 26px;
    border-radius: 2px;
    border: 1px solid #999999;

    .inp_search {
      border: 1px solid #fff;
    }

    .search_btn {
      background: #fff;
      height: 22px;
      padding: 0;
      width: 40px;
    }

    /deep/ .el-input-group__append {
      background: #fff;
      border: 0;
    }

    /deep/ .el-input-group--append {
      .el-input__inner {
        border: 0;
        height: 22px;
      }
    }
  }
}

.list {
  font-size: 14px;

  .check {
    text-align: center;
  }

  .color {
    color: #FA4A14;
    cursor: pointer;
  }

  .list_top {
    padding: 15px 0;
    background: #F5F5F5;
  }

  .list_body {
    .list_item {
      padding: 15px 0;
      border-bottom: 1px solid #e9e9e9;
    }
  }

  /deep/ .el-checkbox__label {
    display: none;
  }
}

.grayDisabled {
  color: #cccccc !important;
  cursor: not-allowed !important;
}
</style>
